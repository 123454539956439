<template>
    <div class="h-100 position-relative">
        <b-card no-body>
            <template #header>
                <div
                    class="w-100 d-flex flex-column flex-lg-row justify-content-between align-items-center"
                >
                    <b-card-title class="mb-1 mb-lg-0">{{
                        $t('statistics.cancelsTitle')
                    }}</b-card-title>

                    <form-date-range-presets
                        class="ml-auto"
                        :is-loading="isLoading"
                        :show-scale-selector="false"
                        @update="fetchCancelsStatistics"
                    />
                </div>
            </template>
        </b-card>

        <div v-if="isLoading" class="centered">
            <b-spinner variant="primary" />
        </div>

        <b-row v-else>
            <b-col class="mb-2" xl="6">
                <statistics-chart
                    :counters="statistics.cancels.cancels"
                    :total="statistics.cancels.count"
                    statistics-type="cancels"
                >
                    <template #title>
                        {{ $t('statistics.cancels.title') }}
                    </template>
                </statistics-chart>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BCard, BCardTitle, BRow, BCol, BSpinner } from 'bootstrap-vue';
import FormDateRangePresets from '../statistics-components/statistics-form/FormDateRangePresets.vue';
import useStatistics from '../useStatistics';
import StatisticsChart from '../statistics-components/StatisticsChart.vue';

export default {
    components: {
        BCard,
        BCardTitle,
        BRow,
        BCol,
        BSpinner,

        FormDateRangePresets,
        StatisticsChart,
    },

    setup() {
        const { statistics, isLoading, fetchStatistics } = useStatistics();

        const fetchCancelsStatistics = async ({ from, to }) => {
            isLoading.value = true;

            await fetchStatistics({ type: 'cancels', from, to });

            isLoading.value = false;
        };

        return {
            // Reactive data
            statistics,
            isLoading,

            // Methods
            fetchCancelsStatistics,
        };
    },
};
</script>

<style lang="scss" scoped>
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
