import store from '@/store';
import i18n from '@/libs/i18n/index';

import useHandleError from '@/utils/useHandleError';

import { reactive, ref, set } from '@vue/composition-api';

export default function useStatisticsCards() {
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);

    const statistics = reactive({
        cards: {
            total: null,
            active: null,
            blocked: null,
            useless: null,
        },
        cardsAlive: {
            count: null,
            transactions: {
                nothing: null,
                minimal: null,
                advanced: null,
                super: null,
            },
        },
        cancels: {
            count: null,
            cancels: {
                no_payments: null,
                minimals: null,
                advanced: null,
            },
        },
    });

    const isLoading = ref(true);

    const refreshArray = (initialArr, newArr) => {
        const initialArrLength = initialArr.length;

        initialArr.splice(0, initialArrLength, ...newArr);
    };

    const refreshObject = (initialObj, newObj) => {
        Object.keys(newObj).forEach((key) => {
            if (Array.isArray(newObj[key])) {
                refreshArray(initialObj[key], newObj[key]);

                return;
            }

            set(initialObj, key, newObj[key]);
        });
    };

    const fetchStatistics = ({ type, from, to }) => {
        isLoading.value = true;

        return store
            .dispatch('app-statistics/fetchStatistics', {
                locale: i18nReactive.locale,
                type,
                from,
                to,
            })
            .then((responce) => {
                refreshObject(statistics[type], responce.data);
            })
            .catch(showErrorMessage)
            .catch(() => {});
    };

    const fetchCardsStatistics = async ({ from, to }) => {
        isLoading.value = true;

        await Promise.all([
            fetchStatistics({ type: 'cards', from, to }),
            fetchStatistics({ type: 'cardsAlive', from, to }),
        ]);

        isLoading.value = false;
    };

    return {
        // Reactive data
        statistics,
        isLoading,

        // Methods
        fetchStatistics,
        fetchCardsStatistics,
    };
}
