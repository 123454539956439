<template>
    <b-card class="card m-0">
        <template #header>
            <b-card-title>
                <slot name="title">Title</slot>
            </b-card-title>
        </template>

        <div class="h-100 d-flex flex-column">
            <div class="position-relative">
                <chartjs-component-doughnut-chart
                    :height="200"
                    :data="chartCounters"
                    :options="chartDoughnutOptions"
                    class="mb-1"
                />

                <div
                    v-if="total"
                    class="position-absolute centered text-center"
                >
                    <h2 class="m-0">{{ getFormattedThousands(total) }}</h2>

                    <b-card-text class="m-0">
                        {{ $t(`statistics.${statisticsType}.count`) }}
                    </b-card-text>
                </div>
            </div>

            <h5 class="text-body mt-auto">
                {{ $t(`statistics.${statisticsType}.subtitle`) }}
            </h5>

            <b-list-group class="mt-50" flush>
                <b-list-group-item
                    v-for="(counter, key, id) in counters"
                    :key="id"
                    class="w-100 d-flex justify-content-between"
                >
                    <b-card-text class="m-0 d-flex align-items-center">
                        <i
                            class="color-icon mr-50"
                            :style="`background-color: ${getColor(id)}`"
                        />

                        {{ $t(`statistics.${statisticsType}.${key}`) }}
                    </b-card-text>

                    <b-card-text class="m-0 ml-auto">
                        {{ getFormattedThousands(counter) }}
                    </b-card-text>

                    <b-card-text v-if="counter !== total" class="m-0 ml-50">
                        {{ '(' + getPercentage(counter, total) + '%)' }}
                    </b-card-text>
                </b-list-group-item>
            </b-list-group>
        </div>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
} from 'bootstrap-vue';
import { getFormattedThousands, getPercentage } from '@/utils/formatter';
import ChartjsComponentDoughnutChart from '@/views/components/chartjs/ChartjsComponentDoughnutChart.vue';
import {
    chartDoughnutOptions,
    chartColors,
} from '@/views/components/chartjs/chartOptions';
// import { $themeColors } from '@themeConfig';

export default {
    components: {
        BCard,
        BCardTitle,
        BCardText,

        BListGroup,
        BListGroupItem,

        ChartjsComponentDoughnutChart,
    },

    props: {
        total: {
            type: Number,
            default: null,
        },

        counters: {
            type: Object,
            required: true,
        },

        statisticsType: {
            type: String,
            required: true,
            validator: (type) =>
                ['cards', 'cardsAlive', 'cancels'].includes(type),
        },
    },

    data() {
        return {
            chartDoughnutOptions,

            backgroundColor: [
                chartColors.primaryColorShade,
                chartColors.successColorShade,
                chartColors.warningLightColor,
                chartColors.lineChartDanger,
            ],
        };
    },

    computed: {
        parsedLabelsAndData() {
            return Object.keys(this.counters).reduce(
                (acc, key) => {
                    if (key === 'total') return acc;

                    const localeLabel = this.$t(
                        `statistics.${this.statisticsType}.${key}`
                    );
                    acc.labels.push(localeLabel);
                    acc.data.push(this.counters[key]);

                    return acc;
                },
                {
                    labels: [],
                    data: [],
                }
            );
        },

        chartCounters() {
            return {
                datasets: [
                    {
                        ...this.parsedLabelsAndData,
                        backgroundColor: this.backgroundColor,
                        borderWidth: 0,
                        pointStyle: 'rectRounded',
                    },
                ],
            };
        },
    },

    methods: {
        getColor(id) {
            return this.backgroundColor[id] || this.backgroundColor[0];
        },
    },

    setup() {
        return {
            // External methods
            getFormattedThousands,
            getPercentage,
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
}

.card .list-group-item {
    padding: 4px 0 !important;
    border: none !important;
}

.color-icon {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: inline-block;
}

.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
</style>
